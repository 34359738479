import React from "react";
import { graphql, useStaticQuery } from "gatsby"
import ReactMarkdown from "react-markdown";

import ButtonField from './common/form/ButtonField';
import { HOME_SCREEN } from "../constant";

const PrivacyPolicy = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiPrivacyPolicy {
        nodes {
          Description
          title
        }
      }
    }`)
    const {title, Description} = data.allStrapiPrivacyPolicy.nodes[0]
  return (
    <div className="privacyPolicyContainer">
      <div className="content m-3">
        <div className="textContent">
          <h1>{title}</h1> 
          <ReactMarkdown>
            {Description}
          </ReactMarkdown>      
        </div>
        <div className="text-center my-4">
          <ButtonField
            className="btn-primary backToHomeBtn"
            fieldName="backToHome"
            name="BACK TO HOME PAGE"
            to={HOME_SCREEN}
          />
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;