import React from "react";

import Layout from "../components/layout";
import PrivacyPolicy from '../components/privacyPolicys';

const PrivacyPolicyComponent = () => (
   <Layout title="privacy-policy">
      <PrivacyPolicy />
   </Layout>
)

export default PrivacyPolicyComponent;
